<template>
  <div class="col-12 col-lg-7 c g">
    <div class="card">
      <div class="card-header text-center">
        <h4>
          <i class="fa fa-user-edit"></i>
          تعديل ولي الأمر
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <h5 for="">الإسم</h5>
          <input
            type="text"
            class="form-control"
            name=""
            id=""
            aria-describedby="helpId"
            placeholder=""
            v-model="name"
          />
        </div>
        <div class="form-group">
          <h5 for="">الهاتف</h5>
          <input
            type="text"
            class="form-control"
            name=""
            id=""
            aria-describedby="helpId"
            placeholder=""
            v-model="phone"
          />
        </div>
        <div class="form-group">
          <h5 for="">كلمة السر</h5>
          <input
            type="text"
            class="form-control"
            name=""
            id=""
            aria-describedby="helpId"
            placeholder=""
            v-model="password"
          />
        </div>
        <div class="form-group">
          <h5 for="">ملاحظات/تفاصيل</h5>
          <textarea
            class="form-control"
            name=""
            id=""
            aria-describedby="helpId"
            placeholder=""
            v-model="notes"
          ></textarea>
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-relief-success" @click="add()">
            تعديل ولي الأمر
            <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      name: null,
      phone: null,
      notes: null,
      password: null,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    checkPer(this, "parents", "edit");
    var g = this;
    $.post(api + "/admin/parents/parent", {
      jwt: g.user.jwt,
      id: window.location.href.split("/edit/")[1],
    }).then(function (e) {
      e = JSON.parse(e).response;
      g.name = e.name;
      g.phone = e.phone;
      g.notes = e.notes;
      g.password = e.password;
    });
  },
  methods: {
    add() {
      var g = this;
      $.post(api + "/admin/parents/edit", {
        jwt: g.user.jwt,
        id: window.location.href.split("/edit/")[1],
        name: g.name,
        phone: g.phone,
        notes: g.notes,
        password: g.password,
      })
        .then(function (e) {
          e = JSON.parse(e);
          if (e.status != 100) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e.response,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم تعديل ولي الأمر بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            g.$router.push("/parents/view");
          }
        })
        .catch(function () {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ",
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>
